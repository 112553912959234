<template>
  <v-container class="page-x">
    <div class="page-content text-h4">
      Yippee!
      <div class="mt-2">
        <v-icon size="30" color="blue darken-2">mdi-star</v-icon>
        <v-icon size="30" color="blue darken-2">mdi-star</v-icon>
        <v-icon size="30" color="blue darken-2">mdi-star</v-icon>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    language: 1,
    autoplay: false,
    answer: null,
    isLoading: true,
    isAnswered: false,
    timer: null,
  }),
  created() {
    //
  },
  mounted() {
    if (this.$route.query.lang) {
      this.language = this.$route.query.lang;
    }

    this.autoplay =
      this.$route.query.autoplay && this.$route.query.autoplay == 1
        ? true
        : false;

    this.timer = setTimeout(() => {
      this.goNext();
    }, 1000);
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  },
  methods: {
    goPrevious() {
      this.$router.push({
        name: "ABCPage10",
        query: { autoplay: this.autoplay ? 1 : 0, lang: this.language },
      });
    },
    goNext() {
      this.$router.push({
        name: "ABCPage13",
        query: {
          autoplay: this.autoplay ? 1 : 0,
          lang: this.language,
          transition: "slide-top",
        },
      });
    },
    close() {
      this.$router.push({
        name: "ABCPage2",
      });
    },
  },
};
</script>

<style scoped>
.page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: #000 solid 1px;

  position: fixed;
  z-index: 99;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  max-width: 480px;
}

.page {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  border: #000 solid 1px;
  background: rgb(255, 255, 255) !important;
}

.page-x {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border: #000 solid 1px;
  background: rgb(255, 255, 255) !important;
}

.buttons {
  display: flex;
}

.btn-dis {
  opacity: 0.5;
}

.vocab {
  text-decoration: underline;
  font-weight: 900;
}
</style>